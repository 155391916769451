import axios from '@axios';

export const fetchEvaluations = (queryParams) => {
  const url = queryParams.id ? `/api/evaluation/evaluation-request/${queryParams.id}` : '/api/evaluation';
  return axios.get(url, { params: queryParams });
};

export const fetchEvaluation = (id) => axios.get(`/api/evaluation/${id}`);

export const fetchAllAreas = async () => {
  return await axios.get('/api/area/list');
};