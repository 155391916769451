import axios from '@axios';

export default {
  namespaced: true,
  root:true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    
    fetchEvaluationRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/evaluation-request', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContractEvaluationRequests(ctx, { queryParams, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/contract/${id}/evaluation-request`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEvaluationRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/evaluation-request/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvaluationRequest(ctx, data) {
      const id = data.get('id');
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/evaluation-request/${id}`, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    saveMessage(ctx, { discussionData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/evaluation-request/${id}/discussion`, discussionData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    assignEvaluator(ctx, { evaluationRequestData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/evaluation-request/${id}/assign-evaluator`, evaluationRequestData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteEvaluationRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/evaluation-request/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addEvaluationRequest(ctx, evaluationRequestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/evaluation-request', evaluationRequestData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContacts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client-contact/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContractOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/contract/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistricts(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAreas(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/area/list/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAllAreas(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/area/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEvaluators(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/company/permission/evaluation_inspect')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchFeatures(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/property-type/${id}/features`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getDisplayProp(feature, prop, defaultValue){
      const value = feature.feature.value_options && feature.feature.value_options.display && feature.feature.value_options.display[prop]? feature.feature.value_options.display[prop]: defaultValue;
      return value;
    },

    fetchUsers(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/company/permission/all')
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      },
  },
};
