export const propertyEvaluationMixin = {
  computed: {
    computeResult() {
      return (formula, fieldName, fields, current) => {
        if (!formula || !fields || !current) return null;

        // Regular expression to split the formula by operators, keeping the operators
        const regex = /([+\-*/])/;
        const parts = formula.split(regex).filter(Boolean); // Split and clean up

        if (parts.length !== 3) return null; // Expecting [fieldName1, operator, fieldName2]

        // Map field names to their current values
        const fieldName1 = parts[0].trim();
        const operator = parts[1];
        const fieldName2 = parts[2].trim();
        // Find the field objects by name and get their current values
        const value1 = current[fieldName1] ? parseFloat(current[fieldName1]) : NaN;
        const value2 = current[fieldName2] ? parseFloat(current[fieldName2]) : NaN;

        if (isNaN(value1) || isNaN(value2)) return 0;
        const calculate = (value1, operator, value2) => {
          switch (operator) {
            case '*': return value1 * value2;
            case '+': return value1 + value2;
            case '-': return value1 - value2;
            case '/': return value2 !== 0 ? value1 / value2 : 'Error'; // Avoid division by zero
            default: return 0;
          }

        }
        current[fieldName] = calculate(value1, operator, value2);
      };
    },
    computeFieldsResult() {
      return (formula, fieldName, features, data, current) => {
        // Split formula into parts
        const parts = formula.match(/[\w.]+|[-+*/]/g);
        if (!parts) return null;

        // Initialize an object to map feature names to their current values
        let currentValues = data.reduce((acc, feature) => {
          const featureData = features.find(d => d.feature.id === feature.feature_id);
          if (featureData) {
            acc[featureData.feature.name] = feature.value;
          }
          return acc;
        }, {});

        // Replace field names in the formula with their corresponding values
        const replacedParts = parts.map(part => {
          if (["+", "-", "*", "/"].includes(part)) return part; // Return the operator as is
          return this.getNestedValue(currentValues, part) ?? 0; // Use the value or NaN if not found
        });

        // Check for NaN values to ensure all replacements were successful
        if (replacedParts.some(part => !["+", "-", "*", "/"].includes(part) && isNaN(part))) {
          return 0;
        }

        // Manually compute the result
        let result = replacedParts[0];
        for (let i = 1; i < replacedParts.length; i += 2) {
          const operator = replacedParts[i];
          const nextValue = replacedParts[i + 1];
          switch (operator) {
            case '+': result += nextValue; break;
            case '-': result -= nextValue; break;
            case '*': result *= nextValue; break;
            case '/':
              if (nextValue === 0) return 'Error'; // Prevent division by zero
              result /= nextValue; break;
            default: return 'Unsupported operation';
          }
        }
        // Assign the result to the 'current' object for the given field name
        current[fieldName] = result;
      };
    }
  },
  methods: {
    getSettlmentIndex(featureId) {
      const index = this.settlements[0].settlemts2.findIndex(
        (feature) => feature.feature.id === featureId,
      );

      return index;
    },
    addArea() {
      this.evaluationData.areas.push({});
    },
    removeArea(index) {
      this.evaluationData.areas.splice(index, 1);
    },
    onAreaChange(item, index) {
      this.evaluationData.areas[index].label = this.allAreas.find(
        (area) => area.value === item.area_id,
      ).name;
    },
    addSettlment() {
      const settlment = {
        request_id: this.request.id,
        feature_id: this.otherSettlment.feature.id,
        property_feature_id: this.otherSettlment.feature.id,
        property_id: this.request.property.id,
        label: this.otherSettlment.feature.display_name,
        value: {},
      };
      this.evaluationData.comparatives[0].settlments.push(settlment);
      this.evaluationData.comparatives[1].settlments.push(JSON.parse(JSON.stringify(settlment)));
      this.evaluationData.comparatives[2].settlments.push(JSON.parse(JSON.stringify(settlment)));
    },
    removeSettlment(index) {
      this.evaluationData.comparatives[0].settlments.splice(index, 1);
    },
    calculatePrimaryTotalAdjustment(index) {
      // get the comparative by index
      const comparative = this.evaluationData.comparatives[index];
      if (!comparative) return 0;
      const total = comparative.settlments.filter((s) => this.settlements[0].settlemts1.findIndex(
        (feature) => {
          if (s.value && typeof s.value.settlement_ratio !== 'undefined') return feature.feature.id === s.property_feature_id && s.value && typeof s.value.settlement_ratio !== 'undefined';
        },
      ) > -1)
        // calculate total value of settlments
        .reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.value.settlement_ratio), 0);
      this.evaluationData.comparatives[index].primary_total_adjustments = total;
      return total;
    },
    calculatePrimaryAdjustmentValue(index) {
      const total = this.calculatePrimaryTotalAdjustment(index);
      if (total == 0) return 0;
      const comparative = this.evaluationData.comparatives[index];
      const primaryPrice = +comparative.price_per_meter || 0;
      const adjustmentValue = (total / 100) * primaryPrice;
      this.evaluationData.comparatives[index].primary_adjustments_value = adjustmentValue;
      return adjustmentValue;
    },
    calculatePrimaryPriceAfterAdjustments(index) {
      const adjustmentValue = this.calculatePrimaryAdjustmentValue(index);
      const comparative = this.evaluationData.comparatives[index];
      const primaryPrice = +comparative.price_per_meter || 0;
      const priceAfterAdjustments = primaryPrice + adjustmentValue;
      this.evaluationData.comparatives[index].primary_price_after_adjustments = priceAfterAdjustments;
      return priceAfterAdjustments;
    },
    calculateTotalAdjustment(index) {
      const comparative = this.evaluationData.comparatives[index];
      if (!comparative || !comparative.price_per_meter) return 0;
      const total = comparative.settlments
        .filter(s => this.settlements[0].settlemts2.findIndex(
          feature => feature.feature.id === s.property_feature_id && s.value && typeof s.value.settlement_ratio !== 'undefined'
        ) > -1)
        .reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.value.settlement_ratio || 0), 0);
      this.evaluationData.comparatives[index].total_adjustments = total;
      return total;
    },
    calculateAdjustmentValue(index) {
      const adjustmentValue = this.calculatePrimaryPriceAfterAdjustments(index) * this.calculateTotalAdjustment(index) / 100;
      this.evaluationData.comparatives[index].adjustments_value = adjustmentValue;
      return adjustmentValue;
  },  
    calculatePriceAfterAdjustments(index) {

      const priceAfterAdjustments = this.calculatePrimaryPriceAfterAdjustments(index) + this.calculateAdjustmentValue(index);
      this.evaluationData.comparatives[index].price_after_adjustments = priceAfterAdjustments;
      return priceAfterAdjustments;
    },
    calculateValuePerMeter() {
      const comparatives = this.evaluationData.comparatives;
      let meter_price = 0;
      this.evaluationData.value_per_meter = 0;
      for (let i = 0; i < comparatives.length; i++) {
        const comparative = comparatives[i];
        // Ensure that the comparative has the necessary properties to perform the calculation
        if (comparative && comparative.price_after_adjustments && comparative.relative_adjustment) {
          const adjustmentFactor = comparative.relative_adjustment / 100;
          meter_price += comparative.price_after_adjustments * adjustmentFactor;
        }
      }

      meter_price = Math.round(meter_price * 100) / 100; // Rounding to two decimal places
      this.evaluationData.value_per_meter = meter_price;
      return meter_price;
    }

  },
};
