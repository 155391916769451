import { definitions, methodExtraction, evaluationMethodExtractionMap, baseValueMethods } from './definitions';

export const propertyOtherInfoMixin = {
  data() {
    return {
      definitions,
      methodExtraction,
      evaluationMethodExtractionMap,
      baseValueMethods,
    };
  },
  methods: {
    featureChanged(feature, groupIndex, event) {
      if (feature.feature.name == 'secondary_valuation_method') {
        // Filter options for custom_secondary_valuation_method based on event
        const customSecondaryValuationMethod = this.features[groupIndex].features.find(
          (f) => f.feature.name === 'custom_secondary_valuation_method',
        );
        // Check if the value includes "أخرى"
        if (event.includes('أخرى')) {
          // Set a flag to indicate that custom_secondary_valuation_method input should be shown
          customSecondaryValuationMethod.feature.disabled = true;
        } else {
          // Reset the flag if the condition is not met
          customSecondaryValuationMethod.feature.disabled = false;
        }
      } else if (feature.feature.name == 'evaluation_method') {
        // We reset the secondary_valuation_method
        const secondaryValuationMethod = this.features[
          groupIndex
        ].features.find((f) => f.feature.name == 'secondary_valuation_method');
        const index = this.evaluationData.features.findIndex(
          (f) => f.feature_id == secondaryValuationMethod.property_feature_id,
        );

        this.evaluationData.features[index].value = null;
        // we update the valuation description
        const valuationMethodDescription = this.features[
          groupIndex
        ].features.find(
          (f) => f.feature.name == 'evaluation_method_description',
        );
        const description = this.definitions.find((d) => d.name == event);
        if (description) {
          const index = this.evaluationData.features.findIndex(
            (f) => f.feature_id == valuationMethodDescription.property_feature_id,
          );

          this.evaluationData.features[index].value = description.description;
        }
        // Filter options for valuation_method_extraction based on event
        const valuationMethodExtraction = this.features[groupIndex].features.find(
          (f) => f.feature.name === 'valuation_method_extraction',
        );
        valuationMethodExtraction.feature.value_options.options = evaluationMethodExtractionMap[event];
      } else if (feature.feature.name === 'valuation_method_extraction') {
        // Update the valuation extraction description
        const valuationExtractionDescription = this.features[
          groupIndex
        ].features.find(
          (f) => f.feature.name === 'valuation_method_extraction_description',
        );
        const extractionDescription = this.methodExtraction.find(
          (d) => d.name === event,
        );
        
        if (extractionDescription) {
          const index = this.evaluationData.features.findIndex(
            (f) => f.feature_id === valuationExtractionDescription.property_feature_id,
          );

          this.evaluationData.features[index].value = extractionDescription.description;
        }
      } else if (feature.feature.name === 'valuation_basis') {
        // Update the base value method description
        const baseValueMethodDescription = this.features[
          groupIndex
        ].features.find(
          (f) => f.feature.name === 'valuation_basis_description',
        );
        const baseValueDescription = Object.keys(this.baseValueMethods).find(
          (d) => d === event,
        );
        
        if (baseValueDescription) {
          const index = this.evaluationData.features.findIndex(
            (f) => f.feature_id === baseValueMethodDescription.property_feature_id,
          );

          this.evaluationData.features[index].value = this.baseValueMethods[baseValueDescription];
        }
      }
    },
  },
};
