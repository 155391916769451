import { fetchAllAreas } from '../services/evaluation-service';
import { ref } from '@vue/composition-api';

export function useEvaluation() {
  const allAreas = ref([]);

  const loadAllAreas = async () => {
    try {
      const {data} = await fetchAllAreas();
      allAreas.value = processAreasData(data.data);
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };

  return { allAreas, loadAllAreas };
}

function processAreasData(rawData) {
  // Assuming rawData is an array of area objects
  return rawData.map((area) => ({
    ...area,
    value: area.id, // adding a 'value' property
    label: area.name, // adding a 'label' property
  }));
}
