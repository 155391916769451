export const definitions = [
  {
    name: 'اسلوب السوق',
    description: 'أسـلوب التقييـم العقـاري بالاعتماد على السـوق هـي طريقـة تقديـر قيمـة عقـار أو أملاك عقاريـة بالاعتماد على المقارنة الســوقية بين العقــار محــل التقييــم والعقــارات المشابهة الموجودة في نفــس المنطقة والتــي تــم بيعهــا حديثًـا.',
  },
  {
    name: 'اسلوب الدخل',
    description: 'يقـدم أسـلوب الدخـل مؤشراً للقيمـة عـن طريـق تحويـل التدفقـات النقديـة المستقبلية إلى قيمـة حاليـة واحـدة. ووفقاً لهـذا الأسلوب يتـم تحديـد قيمـة الأصل بالرجـوع إلى قيمـة الإيرادات والتدفقـات النقديـة التـي يولدهـا الأصل أو التكاليـف التي يوفرها',
  },
  {
    name: 'اسلوب التكلفة',
    description: 'يقــدم أسلوب التكلفة مؤشّراً للقيمة باستخدام المبدأ الاقتصادي الــذي ينص على أن المشتري لن يدفع لأصلٍ ما أكثر من تكلفة الحصول على أصلٍ ذي منفعة مماثلة ســواءً عن طريق الشراء أو البناء ما لم تكن هناك عوامل متعلقة بالوقت، أو الإزعاج، أو المخاطر، أو أي عوامل أخرى متعلقة. ويقدم الأسلوب مؤشراً للقيمة عـن طريق حساب التكلفة الحالية لإحلال أو إعادة إنتاج الأصل، ومن ثـم خصم الإهلاك المادي وجميع أشكال التقادم الأخرى.',
  },
  {
    name: 'القيمة السوقية',
    description: 'هي المبلغ المقدر الذي ينبغي على أساسه مبادلة الأصول أو الالتزامات في تاريخ التقييم بين مشتر راغب وبائع راغب في إطار معاملة على أساس محايد بعد تسويق مناسب حيث يتصرف كل طرف من الأطراف بمعرفة وحكمة دون قسر أو إجبار.',
  },
  {
    name: 'الإيجار السوقي',
    description: 'هو عبارة عن المبلغ التقديري الذي على أساسه ينبغي تأجير العقار في تاريخ التقييم بين مؤجر راغب ومستأجر راغب، وفي إطار معاملة على أساس محايد بعد تسويق مناسب حيث يتصرف كل طرف من الأطراف على أساس من المعرفة والحصافة دون قسر أو إجبار.',
  },
  {
    name: 'القيمة المنصفة',
    description: ' هي السعر المقدر لنقل ملكية أحد الأصول أو الالتزامات بين أطراف محددين وراغبين، وعلى معرفة بحيث تظهر مصالح الأطراف في هذه القيمة (لا ينطبق على التقييمات لأغراض التقارير المالية).',
  },
  {
    name: 'القيمة الاستثمارية',
    description: 'هي قيمة أصل ما بالنسبة للمالك أو المالك المحتمل لأغراض استثمارية أو تشغيلية خاصة به.',
  },
  {
    name: 'القيمة التكاملية',
    description: 'هي عنصر إضافي للقيمة، وتنتج عن ضم أصلين او حصتين أو أكثر معاً، تكون القيمة الناتجة أكثر من مجموع القيم المنفردة.',
  },
  {
    name: 'قيمة التصفية',
    description: ' هي المبلغ الناتج عن بيع أصل أو مجموعة من الأصول تدريجياً على أجزاء متفرقة.            ',
  },
  {
    name: 'اسس أخرى للقيمة',
    description: 'القيمة العادلة (منظمة التعاون الاقتصادي والتنمية) -  القيمة السوقية العادلة (دائرة الإرادات الداخلية للولايات المتحدة الأمريكية) - القيمة العادلة (القانونية أو التشريعية)',
  },
  {
    name: 'قسري',
    description: 'يستخدم مصطلح البيع القسري غالبا فى الظروف التي يكون فيها البائع تحت الاجبار للقيام بالبيع،ونتيجة لذلك تصبح فترة التسويق غير كافية ، ويمكن أن لا يستطيع المشترون الفقيام بأعمال الفحص النافى للجهالة . ويعتمد السعر الذى يمكن الحصول عليه فى هذه الظروف على طبيعة الضغوط على البائع  وأسباب عدم إمكانية القيام بالتسويق المناسب .',
  },
  {
    name: 'اسلوب القيمة المتبقية',
    description: 'طريقة القيمة المتبقية. تُستخدم طريقة القيمة المتبقية لتحليل عروض التطوير أو المعالجة لتحديد صلاحيتها وسلامتها، أو لتحديد السعر الذي يمكن دفعه مقابل شراء الموقع أو المرافق الحالية. عادة ما تكون هذه القطع أرضًا فارغة أو قطع أرض بها مبانٍ قديمة يتم إزالتها أو ترميمها وتجديدها.',
  },

];
export const methodExtraction = [
  {
    name: 'طريقة المقارنات',
    description: 'الطريقة التي تستخدم المضاعفات التجارية للمعاملات لتحديد القيمة.',
  },
  {
    name: 'طريقة الاستثمار',
    description: 'الطريقة التي تحسب إجمالي القيمة الإيجارية ثم تقوم بخصم المصروفات السنوية.',
  },
  {
    name: 'طريقة التدفقات النقدية المخصومة (DCF)',
    description: 'الطريقة التي تستخدم في تقييم الاستثمارات بخصم تدفقات الدخل.',
  },
  {
    name: 'طريقة القيمة المتبقية',
    description: 'الطريقة التي تمزج بين أسلوب السوق وأسلوب الدخل وأسلوب التكلفة.',
  },
  {
    name: 'طريقة الأرباح',
    description: 'الطريقة التي تعتمد على الأرباح المحصلة من العقار لتحديد القيمة.',
  },
  {
    name: 'طريقة تكلفة الإحلال',
    description: 'تكلفة تحديد السعر الذي يدفعه المشترك للحصول على منفعة مشابهة للأصل.',
  },
  {
    name: 'طريقة تكلفة إعادة الإنتاج',
    description: 'تكلفة إعادة إنتاج نسخة طبق الأصل من الأصل.',
  },
  {
    name: 'طريقة الجمع',
    description: 'الطريقة التي تحسب قيمة الأصل من خلال إضافة القيم المنفصلة للأجزاء المكونة له.',
  },
];
export const evaluationMethodExtractionMap = {
  'اسلوب السوق': ['طريقة المقارنات'],
  'اسلوب الدخل': [
    'طريقة الاستثمار',
    'طريقـة التدفقـات النقديـة المخصومة (DCF)',
    'طريقة القيمة المتبقية',
    'طريقـة الأرباح',
  ],
  'اسلوب التكلفة': [
    'طريقة تكلفة الإحلال',
    'طريقة تكلفة إعادة الإنتاج',
    'طريقة الجمع',
  ],
};

export const baseValueMethods = {
  "القيمة السوقية": "هي المبلغ المقدر الذي ينبغي على أساسه مبادلة الأصول أو الالتزامات في تاريخ التقييم بين مشتر راغب وبائع راغب في إطار معاملة على أساس محايد بعد تسويق مناسب حيث يتصرف كل طرف من الأطراف بمعرفة وحكمة دون قسر أو إجبار.",
  "الإيجار السوقي": "هو عبارة عن المبلغ التقديري الذي على أساسه ينبغي تأجير العقار في تاريخ التقييم بين مؤجر راغب ومستأجر راغب، وفي إطار معاملة على أساس محايد بعد تسويق مناسب حيث يتصرف كل طرف من الأطراف على أساس من المعرفة والحصافة دون قسر أو إجبار.",
  "القيمة المنصفة": "هي السعر المقدر لنقل ملكية أحد الأصول أو الالتزامات بين أطراف محددين وراغبين، وعلى معرفة بحيث تظهر مصالح الأطراف في هذه القيمة (لا ينطبق على التقييمات لأغراض التقارير المالية).",
  "القيمة الاستثمارية": "هي قيمة أصل ما بالنسبة للمالك أو المالك المحتمل لأغراض استثمارية أو تشغيلية خاصة به.",
  "القيمة التكاملية": "هي عنصر إضافي للقيمة، وتنتج عن ضم أصلين او حصتين أو أكثر معاً، تكون القيمة الناتجة أكثر من مجموع القيم المنفردة.",
  "قيمة التصفية": "هي المبلغ الناتج عن بيع أصل أو مجموعة من الأصول تدريجياً على أجزاء متفرقة.",
  "اسس أخرى للقيمة": "القيمة العادلة (منظمة التعاون الاقتصادي والتنمية) -  القيمة السوقية العادلة (دائرة الإرادات الداخلية للولايات المتحدة الأمريكية) - القيمة العادلة (القانونية أو التشريعية)",
  "قسري": "يستخدم مصطلح البيع القسري غالبا فى الظروف التي يكون فيها البائع تحت الاجبار للقيام بالبيع،ونتيجة لذلك تصبح فترة التسويق غير كافية ، ويمكن أن لا يستطيع المشترون الفقيام بأعمال الفحص النافى للجهالة . ويعتمد السعر الذى يمكن الحصول عليه فى هذه الظروف على طبيعة الضغوط"
}