<template>
  <b-row v-if="request">
    <b-col
      cols="12"
      md="12"
    >
      <b-alert
        v-model="showMessage"
        dismissible
        :variant="messageType"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="InfoIcon"
          />
          <span
            class="ml-25"
            v-html="message"
          />
        </div>
      </b-alert>
    </b-col>
    <b-col
      cols="12"
      md="6"
      lg="6"
    >
      <!-- Field: lat -->
      <validation-provider
        #default="validationContext"
        vid="lat"
        name="lat"
        rules="required"
      >
        <b-form-group
          label="خط العرض"
          label-for="lat"
          :state="getValidationState(validationContext)"
        >
          <b-form-input
            id="lat"
            v-model="evaluationData.property.lat"
            @input="updateLocation('lat', $event)"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <b-col
      cols="12"
      md="6"
      lg="6"
    >
      <!-- Field: lng -->
      <validation-provider
        #default="validationContext"
        vid="lng"
        name="lng"
        rules="required"
      >
        <b-form-group
          label="خط الطول"
          label-for="lng"
          :state="getValidationState(validationContext)"
        >
          <b-form-input
            id="lng"
            v-model="evaluationData.property.lng"
            @input="updateLocation('lng', $event)"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <b-col
      cols="12"
      md="12"
      lg="12"
      class="mt-0"
    >
      <small>
        رابط الخريطة: <a
          :href="mapLink"
          target="_blank"
        >{{ mapLink }}
          <feather-icon
            icon="ExternalLinkIcon"
            size="12"
          />
        </a>
      </small>
    </b-col>
    <b-col
      cols="12"
      md="12"
      lg="12"
    >
      <l-map
        ref="leafletMap"
        :zoom="zoom"
        :center="center"
        @click="updateMarkerLatLng"
      >
        <v-tilelayer-googlemutant
          :key="googleMapKey"
          ref="googleMutantMap"
          :apikey="apiKey"
          type="roadmap"
          lang="ar"
          :options="options"
        />
        <l-marker :lat-lng="markerLatLng" />
        <l-control class="">
          <b-button-group style="font-family: Cairo, Helvetica, Arial, serif;">
            <b-button
              :variant="options.type == 'satellite'? 'primary':'light'"
              @click="updateMapType('satellite')"
            >
              <feather-icon
                class="mr-25"
                icon="LayersIcon"
              />
              قمر صناعي
            </b-button>
            <b-button
              :variant="options.type == 'roadmap'? 'primary':'light'"
              @click="updateMapType('roadmap')"
            >
              <feather-icon
                class="mr-25"
                icon="LayersIcon"
              />
              تضاريس
            </b-button>
          </b-button-group>
        </l-control>
      </l-map>
      <b-col
        cols="12"
        md="12"
        lg="12"
      >
        <b-button
          variant="primary"
          class="mt-1 mb-sm-0 mr-0 mr-sm-1"
          @click="setLocation"
        >
          إستخدام الموقع الحالي
        </b-button>
      </b-col>
      <!-- Loop for images with type "صورة المخطط" using computed property -->
      <b-row
        v-for="(attachment, index) in this.evaluationData.attachments"
        :id="`attachment-${attachment.id}`"
        :key="`attachment-${attachment.id}`"
        ref="attachmentRow"
      >
        <b-col
          cols="12"
          md="5"
          lg="5"
        >
          <!-- Field: path -->
          <!-- rules="required" -->
          <validation-provider
            #default="validationContext"
            :vid="`attachment_path_${index}`"
            :name="`attachment_path`"
            rules="size:4096"
          >
            <b-form-group
              label="صورة المخطط"
              :label-for="`path_${index}`"
              :state="getValidationState(validationContext)"
            >
              <b-form-file
                :id="`attachment_path_${index}`"
                v-model="attachment.path"
                accept="image/*"
                browse-text="اختر الصور"
                placeholder="اختر ملفا ..."
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormFile,
  BButton,
  BButtonGroup,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
} from 'bootstrap-vue';
import {
  LMap, LTileLayer, LMarker, LControl,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import evaluationStore from '../../evaluationStore';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  components: {
    BFormFile,
    BButton,
    BButtonGroup,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin],
  props: {
    request: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      options: {
        type: 'satellite',
      },
      apiKey: 'AIzaSyBZWiGSnsqX38EMwg-vdOS-7TxUgCpSc1U',
      map: null,
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 16,
      // center: [],
      // markerLatLng: [],
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  computed: {
    filteredAttachment() {
      return this.evaluationData.attachments.find((attachment) => attachment.type === 'صورة المخطط');
    },
    mapLink() {
      return `https://www.google.com/maps/search/?api=1&query=${this.evaluationData.property.lat},${this.evaluationData.property.lng}`;
    },
  },
  mounted() {
    this.$root.$on('validateLocation', () => {
      this.evaluationData.attachments = this.evaluationData.attachments.map(
        (attachment) => {
          if (
            attachment.hasOwnProperty('show_on_report')
              && attachment.show_on_report
          ) {
            attachment.show_on_report = 1; // Set to 1 for true
          } else {
            attachment.show_on_report = 0; // Set to 0 for false
          }

          return attachment;
        },
      );
      this.$root.$emit('locationValidated', {
        success: true,
        data: this.evaluationData,
      });
    });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
    this.$nextTick(() => {
      this.map = this.$refs.leafletMap.mapObject; // work as expected
    });
  },
  beforeDestroy() {
    this.$root.$off('validateForm');
  },
  methods: {
    updateMapType(type) {
      this.options = {
        ...this.options,
        type,
      };
      this.googleMapKey += 1;
    },
    updateLocation(field, event) {
      this.evaluationData.property[field] = event;
      const { lat, lng } = this.evaluationData.property;
      this.markerLatLng = [lat, lng];
      this.center = [lat, lng];
    },
    setLocation() {
      this.map.locate({ setView: true }) /* This will return map so you can do chaining */
        .on('locationfound', (e) => {
          this.markerLatLng = this.center = [e.latitude, e.longitude];
          this.evaluationData = {
            ...this.evaluationData,
            property: {
              ...this.evaluationData.property,
              lat: e.latitude,
              lng: e.longitude,
            },
            lat: e.latitude,
            lng: e.longitude,
          };
        })
        .on('locationerror', (e) => {
          if (e.code == 1) {
          } else if (e.code == 2) {
            alert('الموقع غير متوفر');
          } else if (e.code == 3) {
            alert('انتهت مهلة الطلب');
          }
        });
    },
    updateMarkerLatLng(e) {
      const { lat, lng } = e.latlng;
      this.markerLatLng = [lat, lng];
      this.center = [lat, lng];
      this.evaluationData = {
        ...this.evaluationData,
        property: {
          ...this.evaluationData.property,
          lat,
          lng,
        },
        lat,
        lng,
      };
    },
  },
  setup(props) {
    const { request } = props;
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluation';
    const googleMapKey = ref(0);
    // Register evaluation
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });
    const center = ref([]);
    const markerLatLng = ref([]);
    center.value = [request.property.city.lat, request.property.city.lng];
    markerLatLng.value = [request.property.city.lat, request.property.city.lng];
    const blankEvaluationData = {
      attachments: [
        {
          type: 'صورة المخطط',
          show_on_report: 1,
        },
      ],
      property_id: request.property.id,
      evaluation_request_id: request.id,
      property: {
        lat: request.property.city.lat,
        lng: request.property.city.lng,
      },
    };

    const evaluationData = ref(JSON.parse(JSON.stringify(blankEvaluationData)));
    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(JSON.stringify(blankEvaluationData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetevaluationData);

    return {
      googleMapKey,
      center,
      markerLatLng,
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

  <style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
.leaflet-google-mutant {
  height: 100% !important;
  width: 100% !important;
}
</style>
