<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="propertyInfoRules"
  >
    <b-form
      v-if="evaluationData"
      class="mt-1"
      @submit.prevent="handleSubmit(addEvaluation)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert

            variant="info"
            :show="features.length == 0"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              يمكنك المرور الى المرحلة التالية.
              <br>
              لا يوجد حقول للتعمير للعقار من فئة  <strong>{{ type.name }}</strong>
            </div>
          </b-alert>
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row
        v-for="(group, index) in features"
        :id="group.id"
        :key="index"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="GlobeIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              {{ group.display_name }}
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col
          v-for="(feature, j) in group.features"
          :id="feature.id"
          :key="j"
          cols="12"
          :md="getDisplayProp(feature, 'md', 6)"
          :lg="getDisplayProp(feature, 'md', 6)"
        >
          <!-- Field: feature.name -->
          <validation-provider
            v-if="checkCondition(feature.feature)"
            #default="validationContext"
            :vid="feature.feature.name"
            :name="feature.feature.name"
            :rules="isRequired(feature)?'required':''"
          >
            <b-form-group
              v-if="feature.feature.name !== 'custom_secondary_valuation_method' || feature.feature.disabled"
              :label="isRequired(feature)?feature.feature.display_name + '*':feature.feature.display_name"
              :label-for="feature.feature.name"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-if="feature.feature.value_type === 'enum'"
                v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                :close-on-select="!feature.feature.value_options.multiple"
                :multiple="feature.feature.value_options.multiple"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="getFilteredFeatureOptions(feature, group.features, evaluationData.features)"
                :clearable="true"
                input-id="client"
                @input="featureChanged(feature, index, $event)"
              />
              <b-form-radio-group
                v-else-if="feature.feature.value_type === 'boolean'"
                v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                :options="yesNoOptions"
                class="demo-inline-spacing"
                :name="feature.feature.name"
              />
              <b-form-textarea
                v-else-if="feature.feature.value_type === 'text'"
                :id="feature.feature.name"
                v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
              />
              <b-form-input
                v-else
                :id="feature.feature.name"
                v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { evaluationMixin } from '@/@core/mixins/modules/evaluation';
import { propertyOtherInfoMixin } from '@/@core/mixins/modules/evaluation/propertyOtherInfo';
import evaluationStore from '../../evaluationStore';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationMixin, propertyOtherInfoMixin],
  props: {
    request: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      yesNoOptions: [
        { text: 'نعم', value: true },
        { text: 'لا', value: false },
      ],
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
    this.$root.$on('validateOther', async (data) => {
      this.$refs.propertyInfoRules.validate().then((success) => {
        this.$root.$emit('otherValidated', { success, data: this.evaluationData });
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateProperty');
  },
  methods: {
    isRequired(feature) {
      if (feature.feature.name === 'evaluation_method' || feature.feature.name === 'secondary_valuation_method') {
        return true;
      }
      return false;
    },

    getFilteredFeatureOptions(feature, groupFeatures, evaluationFeatures) {
      if (feature.feature.name === 'evaluation_method' || feature.feature.name === 'secondary_valuation_method') {
        if (this.property.classification.id !== 1 || this.property.property_classification_id !== 1) {
          // Exclude the option "اسلوب القيمة المتبقية" from the options
          return feature.feature.value_options.options.filter(
            (option) => option !== 'اسلوب القيمة المتبقية',
          );
        }

        // If the conditions are not met, return the original options
        return feature.feature.value_options.options;
      }
      return this.getFeatureOptions(feature, groupFeatures, evaluationFeatures);
    },
    validateRules(feature) {
      return true;
    },
  },
  setup(props) {
    const { request } = props;
    const { property } = request;
    const { type } = property;

    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluation';
    const evaluationData = ref(null);
    // Register evaluation
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });

    const features = ref([]);
    // Retrieve related features
    if (type) {
      store
        .dispatch('evaluation/fetchFeatures', { id: type.id })
        .then((response) => {
          const { data } = response.data;
          const evaluationFeatures = [];
          features.value = data.groups.filter((group) => ['property_other_info', 'valuation_method_info', 'authority_requirements'].includes(group.name))
            .map((group) => {
              group.features = group.features.filter((feature) => feature.property_type_id == type.id).map((feature) => {
                evaluationFeatures.push({
                  request_id: request.id,
                  feature_id: feature.feature.id,
                  feature_group_id: group.id,
                  property_id: request.property.id,
                  value: null,
                });
                if (feature.value_type == 'enum' && validateRules(feature.feature)) {
                  feature.value_options.options = feature.value_options.options.map((option) => ({
                    value: option,
                    label: option,
                  }));
                }
                return feature;
              });
              return group;
            });

          const blankEvaluationData = {
            features: evaluationFeatures,
            property_id: request.property.id,
            evaluation_request_id: request.id,
          };

          evaluationData.value = blankEvaluationData;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            contractData.value = undefined;
          }
        });
    }

    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(
        JSON.stringify(blankEvaluationData),
      );
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetevaluationData,
    );

    return {
      property,
      type,
      features,
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
