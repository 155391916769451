<template>
  <div v-if="evaluationRequestData">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="حفظ"
      back-button-text="سابق"
      next-button-text="تالي"
      class="mb-3"
      @on-complete="formSubmitted"
      @on-validate="saveDraft"
      ref="FormWizard"
    >
      <!-- "موقع العقار" tab -->
      <tab-content
        title="موقع العقار"
        :before-change="validateLocation"
      >
        <property-location :request="evaluationRequestData" />
      </tab-content>
      <!-- "معلومات عامة" tab -->
      <tab-content
        title="معلومات عامة"
        :before-change="validateOther"
      >
        <property-other-info :request="evaluationRequestData" />
      </tab-content>

      <!-- "معلومات العقار" tab -->
      <tab-content
        title="معلومات العقار"
        :before-change="validateProperty"
      >
        <property-info :request="evaluationRequestData" />
      </tab-content>

      <!-- "مواصفات العقار" tab  -->
      <tab-content
        title="مواصفات العقار"
        :before-change="validateSpecifications"
      >
        <property-specifications :request="evaluationRequestData" />
      </tab-content>

      <!-- "تقييم العقار" tab -->
      <tab-content
        title="تقييم العقار"
        :before-change="validateEvaluation"
      >
        <property-evaluation
          :request="evaluationRequestData"
          :evaluation="evaluationData"
        />
      </tab-content>

      <!-- social link -->
      <tab-content
        title="المرفقات"
        :before-change="validationAttachmentsForm"
      >
        <attachments :evaluation="evaluationData" />
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import evaluationStore from '../evaluationStore';
import PropertyLocation from './property-location/PropertyLocation';
import PropertyInfo from './property-info/PropertyInfo';
import PropertySpecifications from './property-specifications/PropertySpecifications';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import PropertyEvaluation from './property-evaluation/PropertyEvaluation.vue';
import PropertyOtherInfo from './property-other-info/PropertyOtherInfo.vue';
import { evaluationMixin } from '@/@core/mixins/modules/evaluation';
import Attachments from './attachments/Attachments.vue';

export default {
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    PropertyLocation,
    PropertyInfo,
    PropertySpecifications,
    PropertyEvaluation,
    PropertyOtherInfo,
    Attachments,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationMixin],
  data() {
    return {
      type: 'contract',
      quotations: [],
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    formSubmitted() {
      this.addEvaluation();
    },

    buildFormData(formData, data, parentKey) {
      if (
        data
        && typeof data === 'object'
        && !(data instanceof Date)
        && !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key,
          );
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);

      return formData;
    },
    saveDraft(isValid, tabIndex){
      const totalSteps =this.$refs.FormWizard.tabs.length - 1;
      if (isValid && tabIndex !== totalSteps) {        
        const formData = this.jsonToFormData(this.evaluationData);
        this.$store
          .dispatch('evaluation/draftEvaluation', formData)
          .then((response) => {
            this.handleSuccess(response);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },

    handleSuccess(response) {
      this.$toast.success(response.data.message);

      // Assuming the response contains the evaluation_id
      const evaluationId = response.data.data.id;

      // Add evaluation_id to evaluationData
      this.$set(this.evaluationData, 'evaluation_id', evaluationId);
    },

    handleError(error) {
      if (error.response) {
        if (error.response.status === 422) {
          this.$toast.error(error.response.data.message);
          const { message, errors } = error.response.data;
          if (errors) {
            const errorMessage = {
              message: '',
              type: 'danger',
            };
            Object.values(errors).forEach((err) => {
              errorMessage.message += `${err[0]}<br>`;
            });
            this.displayMessage(errorMessage);
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              text: message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        } else {
          this.$toast.error(error.response.data.error);
        }
      }
      console.log(error);
    },
    addEvaluation() {
      const formData = this.jsonToFormData(this.evaluationData);

      this.$store
        .dispatch('evaluation/addEvaluation', formData)
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push(`/evaluation-request/${this.evaluationRequestData.id}/view`).catch(() => { });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              this.$refs.refFormObserver.setErrors(err.response.data.errors);
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const EVALUATION_STORE_MODULE_NAME = 'evaluation';

    // Register evaluation
    if (!store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.registerModule(EVALUATION_STORE_MODULE_NAME, evaluationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.unregisterModule(EVALUATION_STORE_MODULE_NAME);
    });
    const evaluationRequestData = ref(null);
    const blankEvaluationData = {
      features: [],
      attachments: [{}],
    };
    store
      .dispatch('evaluation/fetchEvaluationRequest', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { data: evaluationRequest } = response.data;
        evaluationRequestData.value = evaluationRequest;
      })
      .catch((error) => {
        console.log(error); 
        if (error.response && error.response.status === 404) {
          evaluationRequestData.value = undefined;
        }
      });
    const evaluationData = ref(JSON.parse(JSON.stringify(blankEvaluationData)));
    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(JSON.stringify(blankEvaluationData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetevaluationData);

    return {
      evaluationRequestData,
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
