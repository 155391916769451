<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="propertyInfoRules"
  >
    <b-form
      v-if="evaluationData"
      class="mt-1"
      @submit.prevent="handleSubmit(addEvaluation)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert

            variant="info"
            :show="features.length == 0"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              يمكنك المرور الى المرحلة التالية.
              <br>
              لا يوجد حقول للتعمير للعقار من فئة  <strong>{{ type.name }}</strong>
            </div>
          </b-alert>
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row
        v-for="(group, index) in features"
        :id="group.id"
        :key="index"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="GlobeIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              {{ group.display_name }}
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <template v-for="(feature, j) in group.features">
          <b-col :id="feature.id" :key="j" cols="12"
          :lg="feature.feature.value_type === 'object' ? 12 : getDisplayProp(feature, 'lg', 6)" :md="feature.feature.value_type === 'object' ? 12 : getDisplayProp(feature, 'md', 6)">
          <!-- Object Type -->
          <template v-if="feature.feature.value_type === 'object'">
            <b-row>
              <h4 class="mb-0 ml-50">
                <b-col cols="12">
                  <b-form-group :label="feature.feature.display_name">
                  </b-form-group>
                </b-col>
              </h4>
            </b-row>
            <b-row>
              <template v-for="(nestedFeature, nestedIndex) in feature.feature.value_options.fields">
                <b-col :key="nestedIndex">
                  <validation-provider v-if="checkFieldCondition(nestedFeature,  evaluationData.features[calculateFeatureIndex(feature)].value)" #default="validationContext"
                    :vid="`${nestedFeature.name}_${j}_${nestedIndex}`" :name="`${nestedFeature.name}_${j}_${nestedIndex}`">
                    <b-form-group :label="nestedFeature.display_name" :label-for="`${nestedFeature.name}_${j}_${nestedIndex}`"
                      :state="getValidationState(validationContext)">
                      <b-input-group v-if="nestedFeature.value_type === 'computed'">
                        <b-form-input type="text" disabled
                          :value="computeResult(nestedFeature.formula, nestedFeature.name, feature.feature.value_options.fields, evaluationData.features[calculateFeatureIndex(feature)].value)"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]" />
                        <b-input-group-append v-if="nestedFeature.suffix" is-text>
                          {{ nestedFeature.suffix }}
                        </b-input-group-append>
                      </b-input-group>
                      <!-- Enum Type -->
                      <v-select v-else-if="nestedFeature.value_type === 'enum'"
                        v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                        :close-on-select="!nestedFeature.value_options.multiple"
                        :multiple="nestedFeature.value_options.multiple"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="nestedFeature.value_options.options" :clearable="true" input-id="client"
                        @input="featureChanged" />

                      <!-- Boolean Type -->
                      <b-form-radio-group v-else-if="nestedFeature.value_type === 'boolean'"
                        v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                        :options="getOptions(nestedFeature)" class="demo-inline-spacing" :name="`${nestedFeature.name}_${j}_${nestedIndex}`" />

                      <!-- Text Type -->
                      <b-form-textarea v-else-if="nestedFeature.value_type === 'text'" :id="nestedFeature.name"
                        v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]" />

                      <!-- Range Type -->
                      <b-form-input
                        v-else-if="nestedFeature.value_type === 'range' && nestedFeature.value_options && nestedFeature.value_options.min !== undefined && nestedFeature.value_options.max !== undefined"
                        type="number"
                        v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                        :min="nestedFeature.value_options.min" :max="nestedFeature.value_options.max" />
                      <flat-pickr v-else-if="nestedFeature.value_type === 'range'"
                        v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                        :config="{ mode: 'range' }" class="form-control flat-picker bg-transparent border-0 shadow-none"
                        placeholder="YYYY-MM-DD" />

                      <!-- Date Type -->
                      <flat-pickr v-else-if="nestedFeature.value_type === 'date'"
                        v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                        :config="datePickerConfig" class="form-control flat-picker bg-transparent border-0 shadow-none"
                        placeholder="YYYY-MM-DD" />

                      <!-- Integer, String, Coordinates (and others not explicitly handled) -->
                      <b-form-input v-else :id="nestedFeature.name"
                        v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                        :type="feature.feature.value_type === 'integer' ? 'number' : 'text'" />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>
            </b-row>
          </template>

          <template v-else>
            <validation-provider v-if="checkCondition(feature.feature)" #default="validationContext"
              :vid="feature.feature.name" :name="feature.feature.name">
              <b-form-group :label="getFeatureLabel(group, j, feature.feature.display_name)"
                :label-for="feature.feature.name" :state="getValidationState(validationContext)">
                <b-input-group v-if="feature.feature.value_type === 'computed'">
                  <b-form-input type="text" disabled
                    :value="computeFieldsResult(feature.feature.value_options.formula, 'value', group.features, evaluationData.features, evaluationData.features[calculateFeatureIndex(feature)])"
                    v-model="evaluationData.features[calculateFeatureIndex(feature)].value" />
                  <b-input-group-append v-if="feature.feature.value_options.suffix" is-text>
                    {{ feature.feature.value_options.suffix }}
                  </b-input-group-append>
                </b-input-group>
                <!-- Enum Type -->
                <v-select v-else-if="feature.feature.value_type === 'enum'"
                  v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                  :close-on-select="!feature.feature.value_options.multiple"
                  :multiple="feature.feature.value_options.multiple"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="feature.feature.value_options.options" :clearable="true" input-id="client"
                  @input="featureChanged" />

                <!-- Boolean Type -->
                <b-form-radio-group v-else-if="feature.feature.value_type === 'boolean'"
                  v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                  :options="getOptions(feature.feature)" class="demo-inline-spacing" :name="feature.feature.name" />

                <!-- Text Type -->
                <b-form-textarea v-else-if="feature.feature.value_type === 'text'" :id="feature.feature.name"
                  v-model="evaluationData.features[calculateFeatureIndex(feature)].value" />

                <!-- Range Type -->
                <b-form-input
                  v-else-if="feature.feature.value_type === 'range' && feature.feature.value_options && feature.feature.value_options.min !== undefined && feature.feature.value_options.max !== undefined"
                  type="number" v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                  :min="feature.feature.value_options.min" :max="feature.feature.value_options.max" />
                <flat-pickr v-else-if="feature.feature.value_type === 'range'"
                  v-model="evaluationData.features[calculateFeatureIndex(feature)].value" :config="{ mode: 'range' }"
                  class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD" />

                <!-- Date Type -->
                <flat-pickr v-else-if="feature.feature.value_type === 'date'"
                  v-model="evaluationData.features[calculateFeatureIndex(feature)].value" :config="datePickerConfig"
                  class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD" />

                <!-- Integer, String, Coordinates (and others not explicitly handled) -->
                <b-form-input v-else :id="feature.feature.name"
                  v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                  :type="feature.feature.value_type === 'integer' ? 'number' : 'text'" />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>
        </b-col>
        </template>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { evaluationMixin } from '@/@core/mixins/modules/evaluation';
import evaluationStore from '../../evaluationStore';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationMixin],
  props: {
    request: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      totalFeatures: 0,
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
    this.$root.$on('validateProperty', async (data) => {
      this.$root.$emit('propertyValidated', { success: true, data: this.evaluationData });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateProperty');
  },
  setup(props) {
    const { request } = props;
    const { property } = request;
    const { type } = property;

    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluation';
    const evaluationData = ref(null);
    // Register evaluation
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });

    const features = ref([]);
    // Retrieve related features
    if (type) {
      store
        .dispatch('evaluation/fetchFeatures', { id: type.id })
        .then((response) => {
          const { data } = response.data;
          const evaluationFeatures = [];
          features.value = data.groups.filter((group) => ['property_info', 'property_front', 'property_floor', 'property_services'].includes(group.name))
            .map((group) => {
              group.features = group.features.map((feature) => {
                evaluationFeatures.push({
                  request_id: request.id,
                  feature_id: feature.feature.id,
                  feature_group_id: group.id,
                  property_id: request.property.id,
                  value: feature.feature.value_type == 'object' ? {
                    ...feature.feature.value_options.fields.reduce((acc, field) => {
                      acc[field.name] = field.default_value || null;
                      return acc;
                    }, {}), 
                  } : feature.feature.default_value || null,
                });
                if (feature.value_type == 'enum') {
                  feature.value_options.options = feature.value_options.options.map((option) => ({
                    value: option,
                    label: option,
                  }));
                }
                return feature;
              });
              return group;
            });

          const blankEvaluationData = {
            features: evaluationFeatures,
          };

          evaluationData.value = blankEvaluationData;
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 404) {
            evaluationData.value = undefined;
          }
        });
    }

    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(
        JSON.stringify(blankEvaluationData),
      );
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetevaluationData,
    );

    return {
      type,
      features,
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
